'use strict';

var _throttle = require('lodash/throttle');

// sub-components of Header
var searchsuggest = require('../components/searchsuggest');
var minicart = require('../components/minicart');
var AccessibilityHelper = require('../helper/accessibility-helper');

const tabletMin = window.matchMedia('(min-width: 768px)');

var header = {
	init: function () {
		header.navigation();
		header.secondaryNavigation();
		if (window.featureToggles["CartV3"]) {
			minicart.initCartV3();
		}
		minicart.init();
		header.sticky();
		header.promoScroller();
		header.showOutdatedBrowserWarning();
		header.tweakNavItemsOnResize();

		// -- Enhanced search (Search as you type)
		var $searchContainer = $('#navigation .search');
		searchsuggest.init($searchContainer, 'q', Resources.SIMPLE_SEARCH, 'suggestions', Urls.searchsuggest);
	},

	/**
	 * @private
	 * @function navigation
	 * @description dropdowns for main navigation
	 */
	navigation: function () {
		// -- Navigation dropdowns
		var $navMenu = $('.nav-menu');
		var $menuContainer = $('.drop-menu-container');
		var $menuBottomBanner = $menuContainer.find('.drop-menu-bottom-banner');
		var $navBox = $('.nav-box');
		var $navItems = $('.nav-item');
		var $subNavMenu = $('.sub-nav-menu');
		var $navTopLinks = $('.top-nav-link.nav-expand');

		// ---- Navigation Events

		// -- Non touch devices have two hovers as events for dropdowns
		if (!("ontouchstart" in document.documentElement)) {
			// Keeps menu as a whole (no bouncing between dropdowns)
			$navBox.on("mouseenter", function() {
				$menuContainer.addClass('open').attr('style', '');
				$navMenu.addClass('over');
			}).on("mouseleave", function() {
				$menuContainer.removeClass('open');
				$navMenu.removeClass('over');
			});

			// Open Menu
			$navItems.on("mouseenter", function() {
				openMenu(this);
			}).on("mouseleave", function() {
				closeMenu();
			});

			// Track the currently active menu item
			$navItems.on('keydown', function(e) {
				var $that = $(this);

				AccessibilityHelper.onSpaceOrEnterAction(e, function() {
					e.preventDefault();
					var $target = $(e.target);

					// If the ENTER key is pressed on an <a> element
					if ($target.is('a.top-nav-link')) {
						// Navigate to the href of the <a> tag
						window.location.href = $target.attr('href');
					} else if ($target.is('button.icon')) {
						var isOpen = $that.hasClass('active');

						// Toggle menu based on current state
						if (isOpen) {
							closeMenu();
						} else {
							$menuContainer.addClass('open').attr('style', '');
							$navMenu.addClass('over');
							openMenu($that);
						}
					}
				});

				AccessibilityHelper.onEscapeKey(e, function() {
					closeMenu();
					setTimeout(function() {
						$that.trigger('focus');
					}, 700);
				});
			});

			// prevent enter/space key event bubble up to nav-item in menu header bar
			$subNavMenu.on('keydown', function(e) {
				AccessibilityHelper.onSpaceOrEnterAction(e, function() {
					e.stopPropagation();
				});
			});

		// -- Touch Devices have additional logic
		} else {
			$navTopLinks.on('click', function(e) {
				// Allow second click through (if active)
				if (!$(this).parent().hasClass('active')) {
					e.preventDefault();
					touchMenu(e);
				}
			});
		}

		// ---- Navigation Functions

		// Adds attribute for sub nav height for animation
		function navHeight(element) {
			var $el = element;

			$('img.lazy', $el).css('display', 'inline-block');
			var $aH = $('.drop-menu', $el).outerHeight();
			if ($('.drop-menu', $el).length == 0) {
				$el.attr('data-height', 0);
			} else {
				$el.attr('data-height', $aH);
			}
		}

		// Opens and animates Sub Nav menu based on height and adds drop-menu-bottom-banner
		function openMenu(element) {
			var $el = $(element);

			navHeight($el);
			$el.addClass('active');

			var $chevron = $el.find('button.icon');
			$chevron.attr('aria-expanded', true);

			$el.find('.top-nav-link.nav-expand').attr('tabindex', '0');

			setTimeout(function() {
				if ($el.hasClass('active')) {
					var $dBtxt = $.trim($('.drop-menu-bottom-banner', '.active').text());
					$menuContainer.animate({
						height: $el.attr('data-height')
					}, 250, function() {
						$('.drop-menu', '.active').addClass('hover');
					});
					$menuBottomBanner.find('.text-replace').text($dBtxt);

					$chevron.removeClass('icon-icon-arrow-down').addClass('icon-icon-arrow-up');
				}
			}, 200);
		}


		// Closes Sub Nav menu if there are are no active nav items
		function closeMenu() {
			$menuContainer.removeClass('open').attr('style', '');
			$('.drop-menu', $('.active')).removeClass('hover');
			$('.nav-menu .active').removeClass('active');
			$('.nav-menu').removeClass('over');

			var $chevrons = $('.nav-menu').find('button.icon');
			$chevrons.attr('aria-expanded', false);

			setTimeout(function() {
				if (!$menuContainer.hasClass('open')) {
					$menuContainer.animate({height: 0}, 250);
				}

				var $chevrons = $('.nav-menu').find('button.icon');
				$chevrons.each(function() {
					var $chevron = $(this);
					if ($chevron.hasClass('icon-icon-arrow-up')) {
						$chevron.removeClass('icon-icon-arrow-up').addClass('icon-icon-arrow-down');
					}
				});
			}, 50);
		}


		// Not to be confused with the side drawer mobile menu (components/mobile.js).
		// This is the dropdown but for touch
		function touchMenu(element) {
			var $el = $(element.target);
			var $navItem = $el.parent('.nav-item');

			// Fresh open
			if (!window.TOUCH_MENU_OPEN) {
				// Keeps menu as a whole (no bouncing between dropdowns)
				$menuContainer.addClass('open').attr('style', '');
				$navMenu.addClass('over');
				// Open Menu
				openMenu($navItem);
				window.TOUCH_MENU_OPEN = true;
				// Add listener for closing outside the header
				// Touch or click, just incase drag touch or hybrid
				window.addEventListener('touchstart', touchMenuListener);
				window.addEventListener('click', touchMenuListener);
			// Sibling open
			} else {
				closeMenu();
				openMenu($navItem);
			}
		}

		// Listener for closing if we're outside the header
		function touchMenuListener(element) {
			if ($(element.target).parents('#header').length == 0) {
				$menuContainer.removeClass('open');
				$navMenu.removeClass('over');
				closeMenu();
				delete window.TOUCH_MENU_OPEN;
				window.removeEventListener('touchstart', touchMenuListener);
				window.removeEventListener('click', touchMenuListener);
			}
		}
	},

	/**
	 * @private
	 * @function secondaryNavigation
	 * @description dropdowns for secondary (right side) navigation
	 */
	secondaryNavigation: function () {
		// -- Secondary Navigation Events

		// Hover and keyboard
		secondaryDropMenu('.icon-icon-store', '.icon-drop-menu');
		secondaryDropMenu('.icon-icon-customer', '.icon-drop-menu');
		if (tabletMin.matches && !window.featureToggles["CartV3"]) {
			secondaryDropMenu('.mybag', '.bag-content');
		}

		// ---- Secondary Navigation Functions

		// Accessible open/close events for secondary (right side) dropdowns
		function secondaryDropMenu(container, content) {
			var hideTimeout;
			$(container).on("mouseenter", function() {
				// clear pending delayed hide.
				if (hideTimeout) {
					clearTimeout(hideTimeout);
					hideTimeout = null;
				}
				$(this).attr('aria-expanded', true).find(content).show().addClass('open');
			}).on("mouseleave", function() {
				var self = this;
				$(self).attr('aria-expanded', false).removeClass('active').find(content).removeClass('open');
				// delay from true hide to allow animation time
				hideTimeout = setTimeout(function() {
					$(self).find(content).hide();
				}, 500);
			}).on('keydown', function(e) {
				var self = this;
				AccessibilityHelper.onSpaceOrEnterAction(e, function() {
					$(self).attr('aria-expanded', true).find(content).show().addClass('open');
				});
				AccessibilityHelper.onEscapeKey(e, function() {
					$(self).attr('aria-expanded', false).find(content).hide().removeClass('open');
					setTimeout(function() {
						$(self).trigger('focus');
					}, 500);
				});
			});
		}
	},

	/**
	 * @private
	 * @function sticky
	 * @description decides when to fix the section to the top of the page
	 */
	sticky: function() {
		const $headerWrap = $('#header');
		const headerHeight = $headerWrap.height();
		const $topBar = $('#header .top-bar');
		const topBarHeight = $topBar.height();
		const searchPopup = $('div.search.bfx-price-container')[0];
		let lastScrollY = window.scrollY;
		let currentScrollY = window.scrollY;

		function swapPosition() {
			currentScrollY = window.scrollY;

			// only commence scrolling feature if search popup is not open
			if (searchPopup && searchPopup.style.display != 'block') {

				if ((currentScrollY > topBarHeight && lastScrollY > currentScrollY) || (
					currentScrollY > headerHeight && lastScrollY < currentScrollY)) {
					$("body").addClass("fill-header-gap");
				} else {
					$("body").removeClass("fill-header-gap");
				}

				if (lastScrollY < currentScrollY) {
					$("body").addClass("scroll-down");
					$("body").removeClass("scroll-up");
				} else {
					$("body").removeClass("scroll-down");
					$("body").addClass("scroll-up");
				}

				// if scrolling down pass the whole header height then hide the whole
				// thing even though it's already out of viewport.
				// we have to do this, because we want slide out the navbar *smoothly*
				// when slightly scroll up
				//
				// *Note 1* apple devices overscroll when going up to the top and so they trigger a
				// scroll down event to get the "frame" back into place.
				// *Note 2* when the search popup is opened, we want to be showing the bar no matter
				// what odd scrolling behaviour apple is triggering.
				if (currentScrollY > 0 && lastScrollY < currentScrollY) {
					if (currentScrollY > headerHeight) {
						$headerWrap.addClass("hide-menu__topbar");
						// move up pdp add-to-cart popup thing since menubar is not showing
						$('#atc-popup').addClass('menubar-hidden');
					}
				} else {
					// if scrolling up then hide only the promo/info bar,
					if (currentScrollY > topBarHeight) {
						$headerWrap.addClass("hide-menu__topbar");
					} else {
						// then at top show promo/info bar
						$headerWrap.removeClass("hide-menu__topbar");
					}

					// move down pdp add-to-cart popup thing since menu is showing again
					$('#atc-popup').removeClass('menubar-hidden');
				}

				lastScrollY = window.scrollY;
			}
		}

		$(window).on('scroll', _throttle(swapPosition, 100));
	},

	/**
	 * @private
	 * @function promoScroller
	 * @description Initiates the promotion swiper
	 */
	promoScroller: function () {
		$(window).on('load', function() {
			var $topGlobalPromoContainer = $('#top-global-promo');
			if ($topGlobalPromoContainer.find('.swiper-slide').length > 1) {
				new Swiper('#top-global-promo', {
					direction: 'vertical',
					pagination: {
						clickable: false
					},
					slidesPerView: 1,
					speed: 400,
					loop: true,
					spaceBetween: 0,
					autoplay: {
						delay: 5000
					},
					preventInteractionOnTransition: true
				});
			}
		});
	},

	/**
	 * @private
	 * @function tweakNavItemsOnResize
	 * @description Show/hide nav items based on window size to avoid overlapping with logo
	 */
	tweakNavItemsOnResize: function () {
		const $logo = $('#header .logo');
		const $desktopNavItems = $('#header .nav-box .nav-item:not(.hide-desktop)');

		if ($logo.length > 0 && $desktopNavItems.length > 0) {
			$(window).on('resize', _throttle(tweak, 250));
			$(window).on('load', tweak);
		}

		function tweak() {
			// only apply when window width >= 1001px
			// as tablet and small desktop view use hamburger menu
			const desktopMin = window.matchMedia('(min-width: 1001px)');
			if (desktopMin.matches) {
				const logoLeft = $logo.offset().left;
				$desktopNavItems.each((i, item) => {
					const $item = $(item);
					const itemRight = $item.offset().left + $item.width();
					if (itemRight > logoLeft) {
						$item.css("visibility", "hidden");
					} else {
						$item.css("visibility", "");
					}
				});
			} else {
				$desktopNavItems.css("visibility", "");
			}
		}
	},

	/**
	 * Show out of date browser warning if applicable
	 */
	showOutdatedBrowserWarning: function () {
		$(window).on('load', function() {
			const messageElement = document.getElementById('browserMessage');
			const userAgent = window.navigator.userAgent;
			if (userAgent && (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('rv:11.0') > -1 || userAgent.indexOf('Trident/7.0') > -1)) {
				messageElement.style.display = 'block';
			}
		});
	}

};

module.exports = header;
