'use strict';

var footer = {
	init: function () {
		var emarsysSignupFooter = '#emarsys-signup-footer-start';
		var emarsysSignup = '#emarsys-signup-main';

		if ($(emarsysSignupFooter).length) {
			footer.emarsysSignupFooter();
		}

		if ($(emarsysSignup).length) {
			footer.emarsysSignup();
		}
	},

	/**
	 * @private
	 * @function emarsysSignupFooter
	 * @description form processing for marketing signup
	 */
	emarsysSignupFooter: function () {
		var $startContainer = jQuery('#emarsys-signup-footer-start');
		var $confirmContainer = jQuery('#emarsys-signup-footer-confirm');

		var $form = jQuery('#emarsys-signup-footer-form');
		var $animate = $form.find('.emarsys-signup-footer-animate');
		var $emailWrap = $form.find('.email-input');
		var $email = $emailWrap.find('input');
		var $joinLoyaltyCheckbox = $('#emarsys-loyalty-footer-agree');
		var $emailError = $emailWrap.find('.field-error');
		var $message = $form.find('.message');
		var $checkbox = $form.find('#emarsys-signup-footer-agree');
		var $submitBtn = $form.find('.submit-btn');

		$checkbox.length && $checkbox.rules('add', {checkboxRequired: true});

		// Function to handle the form submission
		function handleSubmit() {
			if (!$submitBtn.hasClass('disabled') && !$submitBtn.data("clicked")) {
				subscribeEmail();
				$emailWrap.removeClass('field-error');
				$emailError.text('');
				$animate.slideDown(300);
				scrollToSection('footer-global');
				$email.focus();
			}
		}

		// On pressing Enter in the form
		$form.on('keypress', function(e) {
			if (e.which === 13) {
				e.preventDefault(); // Prevent default form submission
				handleSubmit();
			}
		});

		// On clicking the submit button
		$submitBtn.on('click', function(e) {
			e.preventDefault();
			handleSubmit();
		});

		function scrollToSection(sectionId) {
			const targetSection = document.getElementById(sectionId);

			if (targetSection) {
				targetSection.scrollIntoView({behavior: 'smooth', block: 'start'});
			}
		}

		// On losing focus from the email input
		$email.on('blur', function() {
			validateEmail();
		});

		// -- Validation Function
		function validateEmail() {
			var $emailVal = $email.val();
			if ($emailVal != "" && $emailVal.length > 0 && (/^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(jQuery.trim($emailVal)))) {
				$emailWrap.removeClass('field-error');
				$emailError.text('');
				$message.html('');
				$submitBtn.removeClass('disabled');
				return true;
			} else {
				$submitBtn.addClass('disabled');
				$emailWrap.addClass('field-error');
				$emailError.text(Resources.VALIDATE_ERRORPREFIX + " " + Resources.VALIDATE_EMAIL);
				$submitBtn.addClass('disabled');
				return false;
			}
		}

		// -- Subscribe Function
		function subscribeEmail() {
			var $emailVal = $email.val();
			if (validateEmail() && (!$checkbox.length || $checkbox.valid())) {
				// Lock join button to prevent multiple clicks
				$submitBtn.data("clicked", true);

				// Loading
				$submitBtn.addClass("loading");

				setTimeout(function() {
					$email.blur();
				}, 100);

				jQuery.ajax({
					type: "POST",
					url: EmarsysUrls.footerSubscription,
					data: {emailAddress: $emailVal, formatajax: true, joinLoyalty: $joinLoyaltyCheckbox.prop('checked')},

					success: function(response) {
						if (response && response.success) {
							$confirmContainer.html(response.message);
							$startContainer.slideUp(300);
							$confirmContainer.slideDown(300);

							dataLayer.push({'event': 'newsletterSignupFooter', 'successOrFailure': 'success'});
						} else {
							$message.html(response.message);
							dataLayer.push({'event': 'newsletterSignupFooter', 'successOrFailure': 'failure'});
						}
						$submitBtn.removeClass("loading");
						$submitBtn.data("clicked", false);
					},
					error: function() {
						$message.html(Resources.SIGNUP_ERROR);
						dataLayer.push({'event': 'newsletterSignupFooter', 'successOrFailure': 'failure'});
						$submitBtn.removeClass("loading");
						$submitBtn.data("clicked", false);
					}
				});
			}
		}
	},

	/**
	 * @private
	 * @function emarsysSignup
	 * @description form processing for marketing signup
	 */
	emarsysSignup: function () {
		var $emarsysSignup = jQuery('#emarsys-signup-main');
		var $subSource = jQuery('#email-signup-wrapper .subsource');

		var $form = jQuery('#emarsys-signup-form');
		var $firstName = $form.find('input[name="firstname"]');
		var $message = $form.find('.message');
		var $checkbox = $form.find('#emarsys-signup-agree');
		var $submitBtn = $form.find('.submit-btn');
		var $emailWrap = $form.find('.email-input');
		var $email = $emailWrap.find('input');
		var $emailError = $emailWrap.find('.invalid-field-error');

		$form.validate();

		$checkbox.length && $checkbox.rules('add', {checkboxRequired: true});

		// -- Actions
		$form.on('keypress', function(e) {
			if (e.which == 13) {
				subscribeEmail(this);
			}
		});

		$submitBtn.on('click', function(e) {
			e.preventDefault();

			if (!$submitBtn.data("clicked")) {
				subscribeEmail(this);
			}

		});

		$email.on('blur', function() {
			if ($email.val() != "") {
				validateEmail();
			} else {
				$emailError.text('');
			}
		});

		// -- Validation Function
		function validateEmail() {
			var $emailVal = $email.val();
			if ($emailVal.length > 0 && (/^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(jQuery.trim($emailVal)))) {
				$emailWrap.removeClass('field-error');
				$emailError.text('');
				$message.html('');
				return true;
			} else {
				$emailWrap.addClass('field-error');
				$emailError.text(Resources.VALIDATE_EMAIL);
				return false;
			}
		}

		// -- Subscribe Function
		function subscribeEmail() {
			var $emailVal = $email.val();
			if ($form.valid() && validateEmail()) {
				// Lock join button to prevent multiple clicks
				$submitBtn.data("clicked", true);

				// Loading
				$submitBtn.addClass("loading");

				jQuery.ajax({
					type: "POST",
					url: EmarsysUrls.emarsysSignup,
					data: {firstName: $firstName.val(), emailAddress: $emailVal, formatajax: true, subSource: $subSource.val()},

					success: function(response) {
						$submitBtn.removeClass("loading");
						// release the join button
						$submitBtn.data("clicked", false);

						if (response && response.optedInError) {
							$message.html('').html(response.message);
						} else {
							$emarsysSignup.html('').html(response);
						}

					},
					error: function() {
						$message.html('').html(Resources.SIGNUP_ERROR);
						$submitBtn.removeClass("loading");

						// release the join button
						$submitBtn.data("clicked", false);
					}
				});
			}
		}
	}
};

module.exports = footer;
