'use strict';

// -- Sectional requires
var page = require('./section/page');
var header = require('./section/header');
var footer = require('./section/footer');

// -- components
var inputs = require('./components/inputs');
var tabkeylistener = require('./components/tabkeylistener');
var accordion = require('./components/accordion');
var validator = require('./components/validator');
var mobile = require('./components/mobile');
var backToTop = require('./components/backtotop');
var cookies = require('./components/cookies');
var refreshTooltips = require('./components/refresh-tooltips');
var facilemodal = require('./components/facile-modal');
var disambiguator = require('./components/disambiguator');
var lazyload = require('./components/lazyload');
var cartographer = require('./components/cartographer');
var cookieBanner = require('./components/cookie-banner.js');
var signupOverlay = require('./components/signup-overlay');
var signupProcess = require('./components/signup-process');
var gtmTracking = require('./components/gtmTracking');
var print = require('./components/print');


if (!window.appPages) {
	window.appPages = {};
	// Page based js are separated out of app.js, they were named as
	// `page_{pageContext.ns}.js` in the app:build. Each page based js will inject
	// itself into `window.appPages` object by using `assets.js`. Following pages
	// are supported as 2022-12-01. Please reference files under `/pages/` folder.
	// * cart
	// * checkoutsignin
	// * checkout
	// * confirmation
	// * product
	// * giftcard
	// * account
	// * returns
	// * wishlist
	// * loyalty
}

// Group the things
var initializeDOM = function() {
	header.init();
	mobile.init();
	inputs.init();
	tabkeylistener.init();
	refreshTooltips.init();
	facilemodal.init();
	disambiguator.init();
	validator.init();
	cartographer.init();
	cookies.init();
	backToTop.init();
	lazyload.init();
	accordion.init();
	cookieBanner.init();
	initCountryIdentifier();
	signupOverlay.init();
	signupProcess.init();
	gtmTracking.init();
	print.init();
	footer.init();
};

// Track various events
var initializeEvents = function() {

	// if this is the product page, use a different event name for the google tag manager when the viewcart-and-checkout button clicked
	if ($(".pt_productdetails .viewcart-and-checkout a").length >= 1) {
		$(".pt_productdetails .viewcart-and-checkout a").attr("onclick", $(".pt_productdetails .viewcart-and-checkout a").attr("onclick").replace("viewCartAndCheckoutNav", "viewCartAndCheckoutPDP"));
	}

	// This prevents invalid characters from being submitted in any gift messaging in Shipping Step or Gift Card Purchase
	$('textarea[name$="_purchase_message"], textarea[name*="_giftMessage"]').keyup(function() {
		var $th = $(this);
		$th.val($th.val().replace(/[@#$%^&*()=_+"~'`:;/<>\\|{}[\]]/g, function() {
			return '';
		}));
	}).on('paste', function() {
		var $th = $(this);
		setTimeout(function() {
			var _val = $th.val();
			$th.val(_val.replace(/[@#$%^&*()=_+"~'`:;/<>\\|{}[\]]/g, function() {
				return '';
			}));
			$th.val(_val.replace(/\s+/g, ' '));
		}, 100);
	});

	$(document).on('click', '.ib-signup-dialog-trigger', function(e) {
		e.preventDefault();
		const element = this;
		if (element) {
			try {
				const isLoyalty = element.dataset.isLoyalty || false;
				const showConfirmation = element.dataset.showConfirmation || false;
				const userAction = element.dataset.userAction;
				window.uiShowSignUpDialog && window.uiShowSignUpDialog(isLoyalty, showConfirmation, userAction);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	});

	$(document).on('click', '.stac-trigger', function(e) {
		e.preventDefault();
		mobile.closeMobileMenu();
		window.uiShowStacDialog && window.uiShowStacDialog();
	});
};

/**
 * Use '#js-country-language-id' to apply country and language
 * data to the <body> so developers can target country/language
 * specfic styles/JS on the client side
 */
var initCountryIdentifier = function() {
	var $source = $('#js-country-language-id');

	if (!$source || $source.length === 0) {
		return;
	}

	var $body = $('body');
	$body.attr('data-language', $source.data('language').toLowerCase());
	$body.attr('data-country', $source.data('country').toLowerCase());
};

// Build app
var app = {
	init: function () {

		initializeDOM();
		initializeEvents();

		// execute page specific initializations
		$.extend(page, window.pageContext);
		var ns = page.ns;
		if (ns && window.appPages[ns] && window.appPages[ns].init) {
			window.appPages[ns].init();
		}
	}
};

// general extension functions
(function () {
	String.format = function () {
		// eslint-disable-next-line prefer-rest-params
		var s = arguments[0];
		var i; var len = arguments.length - 1;
		for (i = 0; i < len; i++) {
			var reg = new RegExp('\\{' + i + '\\}', 'gm');
			// eslint-disable-next-line prefer-rest-params
			s = s.replace(reg, arguments[i + 1]);
		}
		return s;
	};
})();

// initialize app
$(document).ready(function () {
	app.init();
});
