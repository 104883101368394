'use strict';
var AccessibilityHelper = require('../helper/accessibility-helper');

var printHelper = {
	init: function() {
		$('.print-btn').on('click', function(e) {
			e.preventDefault();
			printHandler($(this));
		});

		$(".print-btn").on('keypress', function(e) {
			var $this = $(this);
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				e.preventDefault();
				printHandler($this);
			});
		});

		function printHandler($this) {
			const contentEl = $this.data('print-content');
			if (contentEl && $(contentEl).length) {
				print($(contentEl).html());
			} else {
				window.print();
			}
		}
	}
};

const print = function(content) {
	const w = window.open();

	w.document.write(content);

	w.document.write('<script>' );
	w.document.write('window.onload = function() { setTimeout(function(){ window.print(); window.close();}, 300); };');
	w.document.write('</script>');

	w.document.close();
	w.focus();
	return true;
};

module.exports = printHelper;
