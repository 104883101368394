'use strict';

// Plugin Documentation: http://jquery.eisbehr.de/lazy/

var lazyload = {
	init: function () {
		if ($(".product-hits").length > 0) {
			// eslint-disable-next-line new-cap
			$("img.lazy").Lazy({
				srcsetAttribute: "data-original",
				attribute: "data-original"
			});
		} else {
			// eslint-disable-next-line new-cap
			if ($("img.lazy").Lazy) {
				// eslint-disable-next-line new-cap
				$("img.lazy").Lazy({
					srcsetAttribute: "data-original",
					attribute: "data-original",
					visibleOnly: true,
					effect: "fadeIn"
				});
			}
		}
		// make sure lazy loader runs and loads initial
		// images in the view without initiating a scroll.
		setTimeout(function() {
			$(window).resize();
		}, 1000);
	},

	/**
	 * @function buildImages()
	 * @description Trigger the lazyload feature for a specific use
	 * @param {String} target The targeted selector string to change
	 * @param {String} container Optional container if inside a scrolling container
	 * @param {Enum} threshold Optional distance before scroll to
	 * @param {String} effect Optional effect to use i.e. fadeIn
	 */
	buildImages: function(target, container, threshold, effect) {
		var params = {
			srcsetAttribute: "data-original"
		};
		if (typeof container !== 'undefined') {
			params.appendScroll = $(container);
		}
		if (typeof threshold !== 'undefined') {
			params.threshold = threshold;
		}
		if (typeof effect !== 'undefined') {
			params.effect = effect;
		}

		// eslint-disable-next-line new-cap
		$(target).Lazy(params);
	}
};

module.exports = lazyload;
