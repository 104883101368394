"use strict";

/* eslint-disable camelcase */

// verify the cookie functionality by writing-reading a test cookie
function verify() {
	var test_cookie_name = "dwTestCookie";
	document.cookie = test_cookie_name + "=OK";

	var cookie_value = getCookie(test_cookie_name);
	if (cookie_value) {
		document.cookie =
			test_cookie_name + "=;expires=Thu, 01-Jan-1970 00:00:01 GMT";
		return true;
	} else {
		$("#cookiesdisabled").show();
		return false;
	}
}

// read the unique user id cookie, and push the value to dataLayer
function pushUid() {
	var uid_cookie_name = "ibuid";

	var cookie_value = getCookie(uid_cookie_name);
	if (/[a-f0-9]{26,}/.test(cookie_value)) {
		dataLayer && dataLayer.push({ib_visitor_uid: cookie_value});
	}
}

function getCookie(name) {
	// first we'll split this cookie up into name/value pairs
	// note: document.cookie only returns name=value, not the other components
	var all_cookies = document.cookie.split(";");
	var temp_cookie = "";
	var cookie_name = "";
	var cookie_value = "";

	for (var __i = 0; __i < all_cookies.length; __i++) {
		// now we'll split apart each name=value pair
		temp_cookie = all_cookies[__i].split("=");

		// and trim left/right whitespace while we're at it
		cookie_name = temp_cookie[0].replace(/^\s+|\s+$/g, "");

		// if the extracted name matches the session cookie name
		if (cookie_name == name) {
			// we need to handle case where cookie has no value but exists (no = sign, that is):
			if (temp_cookie.length > 1) {
				cookie_value = unescape(temp_cookie[1].replace(/^\s+|\s+$/g, ""));
			}

			if (cookie_value.length > 0) {
				return cookie_value;
			}
		}
	}

	return null;
}

/* eslint-enable camelcase */

var cookies = {
	init: function() {
		var _enabled = verify();
		if (_enabled) {
			pushUid();
		}
	}
};

module.exports = cookies;
