'use strict';
var AccessibilityHelper = require('../helper/accessibility-helper');

var backToTop = {
	init: function () {

		// Back to Top
		var didScroll;
		var lastScrollTop = 0;
		var delta = 50;
		var btt = $('.backtotop');

		var hideBtt = function() {
			$(btt).css('right', '-100%');
			// reset lastScrollTop on close
			lastScrollTop = 0;
		};

		var showBtt = function() {
			$(btt).css('right', '0');
		};

		$(window).scroll(function() {
			didScroll = true;
		});

		var hasScrolled = function() {

			var st = $(window).scrollTop();

			// scroll more than delta
			if (Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			if (st > lastScrollTop) {
				// up
				if (st + $(window).height() < $(document).height()) {
					showBtt();
				}
			} else {
				if (st < 200) {
					// down
					hideBtt();
				}
			}
			lastScrollTop = st;
		};

		setInterval(function() {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 150);

		// scroll to top functionality
		$(btt).on("click", function(e) {
			e.preventDefault();
			scrollToTop();
		});

		$(btt).on("keypress", function(e) {
			e.preventDefault();
			AccessibilityHelper.onSpaceOrEnterAction(e, scrollToTop);
		});

		var scrollToTop = function() {
			$("html, body").animate({scrollTop: 0}, 200);
			window.setTimeout(hideBtt, 500);
		};
	}
};

module.exports = backToTop;
