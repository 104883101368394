function init() {
	jQuery(document).ready(function () {
		var facilemodal = require('./facile-modal');
		if ($('#disambiguator').length) {
			facilemodal.showModal('#disambiguator', null, 530, null, null, null);
			$('#disambiguator').find('.stac-trigger').on('click', function() {
				facilemodal.closeFmModal();
			});
		} else {
			document.dispatchEvent(new Event('disambiguator-closed'));
		}
	});
}

module.exports = {
	init: init
};
