'use-strict';

var cookieBanner = {

	showBanner: function (cookieEl) {
		// Set below viewport if Cookie Banner exists
		if (cookieEl.length > 0) {
			cookieBanner.closeBanner(cookieEl);
		}

		// Show the banner after 2 seconds
		setTimeout(function() {
			cookieEl.addClass('show').css('bottom', '');
		}, 2000);

		// Close the Banner and Set Cookie
		cookieEl.on('click', 'a.close', function(e) {
			e.preventDefault();
			cookieBanner.closeBanner(cookieEl);
			cookieBanner.setCookie("cookiePolicy", "true");
		});
	},

	closeBanner: function (cookieEl) {
		var cBH = cookieEl.get(0).scrollHeight;
		cookieEl.css('bottom', -1 * cBH).removeClass('show');
	},

	setCookie: function (cname, cvalue) {
		var d = new Date();
		d.setTime(d.getTime() + (cookieBanner.cookieConsentCookieExpiryInMillis));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},

	getCookie: function (cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},

	init: function () {
		// Load cookie consent cookie expiry value.
		cookieBanner.cookieConsentCookieExpiryInMillis =
			Number($('#cookie_consent_scripts').data('cookieconsentcookieexpiryinmillis'));
		var cookieEl = $('.cookie-policy');
		if (cookieEl.length > 0) {
			var _cookie = cookieBanner.getCookie("cookiePolicy");
			if (_cookie != "true" && _cookie != null) {
				cookieBanner.showBanner(cookieEl);
			}
		}
	}
};

module.exports = cookieBanner;
