'use strict';


/**
 * Initializes key listener so that we can mark the body element
 * with `data-tabfocus`, so we can apply different styles on input fields.
 * Currently, we style different :focus styles depending on the focus was initiated
 * while the tab key was used.
 */
const initTabKeyListener = () => {
	const tabFocus = (event) => {
		if (event.key === 'Tab') {
			$('body').addClass('tabfocus');
			$(window).off('keydown', tabFocus);
			$(window).on('mousedown', mouseDown);
		}
	};
	const mouseDown = () => {
		$('body').removeClass('tabfocus');
		$(window).on('keydown', tabFocus);
		$(window).off('mousedown', mouseDown);
	};

	$(window).on('keydown', tabFocus);
};

module.exports = {
	init: initTabKeyListener
};
