'use strict';

/* global Drive */

// Cartographer documentation found at:
// http://drivecommerce.com/cartographer/developer/


var cartographer = {
	init: function () {

		// Bail if we can't find the place to put it
		var container = '#guided-selling-include';
		if (!$(container).length) {
			return;
		}

		// Protect from no _impressions (google product hits) push undefined error on some servers
		window._impressions = window._impressions || [];

		// Fetch passed data
		var apiKey = $(container).attr('data-api');
		var count = Math.round($(container).attr('data-count')) || 5;
		var group = $(container).attr('data-group');
		var gender = $(container).attr('data-gender');
		var language = $(container).attr('data-language');

		$(container).addClass('group-'+ group);

		// if gender is string "null" reasign as null
		gender === 'null' ? gender = null : null;

		// Overlay Button continue
		if (gender) {
			$('#guided-selling-intro').show();
		} else {
			$('#guided-selling-intro').remove();
		}

		$('#guided-selling-intro .action-continue').on('click', function (e) {
			e.preventDefault();
			$('#guided-selling-intro').fadeOut(300, function() {
				$(this).remove();
			});
			$('.recommender-footer').addClass('open');
		});

		// Let's do this!
		cartographer.buildCartographer(container, apiKey, language, count, gender, group);
	},

	// ----
	// Cartographer API call and builder
	buildCartographer: function(container, apiKey, language, count, gender, group) {

		// Safe loading code, avoid page failure if Cartographer backend is not available for some reason (scheduled maintenance for example)
		(function (d, url, c) {
			var script = d.createElement('script');
			script.type = 'text/javascript';
			if (script.addEventListener) {
				script.addEventListener('load', function () {
					if (c) {
						c();
					}
				}, false);
			} else if (script.readyState) {
				script.onreadystatechange = function () {
					if (this.readyState === 'loaded' && c) {
						c();
					}
				};
			}
			script.src = url;
			d.getElementsByTagName('body')[0].appendChild(script);
			// Load production
		}(document, '//api.cartographer.drivecommerce.com/api/v3/runtime/client?type=production', function () {

			// ================
			// ---- Events
			var events = {};

			// ---- afterInitialize
			// Build stuff a little more
			events['afterInitialize'] = function() {
				if (!gender) {
					// Gender backgrounds
					$(container + ' .slug-start').append('<div class="bg-left"></div><div class="bg-right"></div>');

					var bgGenderSm = $(container).attr('data-bg-gender-sm');
					var bgGenderMens = $(container).attr('data-bg-gender-mens');
					var bgGenderWomens = $(container).attr('data-bg-gender-womens');

					bgGenderSm === 'null' ? bgGenderSm = null : null;
					bgGenderMens === 'null' ? bgGenderMens = null : null;
					bgGenderWomens === 'null' ? bgGenderWomens = null : null;

					cartographer.customBackgroundsGender(container, bgGenderSm, bgGenderMens, bgGenderWomens);
				}

				// Check the backgrounds and go build them if necessary
				var bg = $(container).attr('data-bg');
				var bgSm = $(container).attr('data-bg-sm');

				// if any are string "null" reasign as null
				bg === 'null' ? bg = null : null;
				bgSm === 'null' ? bgSm = null : null;

				cartographer.customBackgrounds(container, bg, bgSm);
			};

			// ---- beforeRetrieveRecommendations
			// Only retrieve recommendations on the completion of last page
			// (assuming page right before results is last)
			events['beforeRetrieveRecommendations'] = function(page) {
				if ((page.page.next('.question-group-results').length > 0) && page.complete) {
					return false;
				} else {
					return true;
				}
			};

			// ---- afterRenderRecommendations
			// When this event fires, cartographer template product tiles have been rendered
			// we then replace those tiles with dynamically loaded content via Product-HitTile
			events['afterRenderRecommendations'] = function() {

				$(container + ' .recommendations').addClass('search-result-items');
				$(container + ' .recommender-products').addClass('swiper-container');
				$('.recommender-products').wrapInner('<div class="swiper-wrapper"></div>');
				var position = 0;

				// Loop through products
				$(container + ' .recommendations .product').each(function () {
					var tile = $(this);
					var productId = tile.data('product-id');
					var url;

					position++;

					// Load and hide the tile
					tile.addClass('grid-tile swiper-slide');

					// Local Dev
					if (window.location.href.indexOf('localhost') >= 0) {
						url = '//staging.ib.icebreaker.com/on/demandware.store/Sites-IB-US-Site/default/Product-HitTile?pid=' + productId + '&position=' + position + '&showdescription=true';
					// On a Server
					} else {
						url = Urls.productHitTile + '?pid=' + productId + '&position=' + position + '&showdescription=true';
					}

					$.ajax({
						type: 'GET',
						url: url,
						dataType: 'html',
						// rating img link updater
						dataFilter: function (response) {
							var replaceWith = 'src="//www.icebreaker.com/on/demandware.static';
							response = response.replace( /src="\/on\/demandware.static/g, replaceWith);
							return response;
						}
					}).done(function (html) {
						// Replace the tile contents
						tile.find('.product-tile').replaceWith(html);

						// Bypass the lazyload images
						tile.find('.lazy').each(function () {
							$(this).attr('src', $(this).data('src'));
						});

						// Copy the product id attribute to a place
						// Where analytics can grab it
						tile.find('.product-tile').each(function () {
							$(this).attr('data-product-id', $(this).attr('data-itemid'));
						});
					});

					// Capture clicks on the product links for engagement analytics
					tile.on('click', 'a', function (event) {
						// Record engagement manually.
						// Even though the method is called "addToCart", it really tracks
						// product engagement, not actual conversion events.
						recommender.addToCart($(event.target).closest('.product'));
					});
				});
			};

			// ---- afterImpression
			events['afterImpression'] = function() {
				cartographer.buildSwiper('.recommender-products');
			};

			// ---- pageAnimation
			events['pageAnimation'] = function(options) {
				// Slide out current to left
				options.from.animate({
					left: '-120%'
				}, 500, function() {
					options.from.hide();
				});

				// Slide in new page from right
				options.to.hide().css({
					'display': 'flex',
					'left': '120%'
				}).animate({
					left: '0'
				}, 500, function() {
					options.to.show();
					options.from.hide();
				});

				return true;
			};

			// ---- routeAdvancePage
			events['routeAdvancePage'] = function() {
				$('.recommender-footer').addClass('open');
			};

			// ---- enterPage
			events['enterPage'] = function(page) {
				if (page.page.is('.slug-start')) {
					$('.recommender-footer').removeClass('open');

					// Reset of first page question if they hit the breadcrumb
					if (page.complete) {
						recommender.undoQuestions($('.question-group-0 .question-0'));
					}
				}

				// Remove potential custom-radio class
				var customRadio = page.page.find('.slug-custom-radio');
				if (customRadio.length > 0) {
					customRadio.removeClass(function (index, className) {
						return (className.match(/(^|\s)answer-\S+/g) || []).join(' ');
					});
				}

				// Hide breadcrumb nav on results
				if (page.pageLast) {
					$('.recommender-footer').removeClass('open');
				}
			};


			// ---- afterActivateQuestion
			// check which type of question we asked
			events['afterActivateQuestion'] = function(options) {
				// uses slug 'custom-radio' (set in cartographer) to pull the answer
				// up to the top .slug-custom-radio so that we can modify css for visual
				if (options.question.data('slug') === 'custom-radio') {
					// need timeout to make sure checked has been added
					setTimeout(
						function() {
							var currentAnswer = options.question.find('.question-radio-button.checked input').attr('id');
							var currentAnswerIndex = currentAnswer.match(/\[(.*?)\]/);
							// remove any existing
							options.question.removeClass(function (index, className) {
								return (className.match(/(^|\s)answer-\S+/g) || []).join(' ');
							});
							// add a new class
							options.question.addClass('answer-' + currentAnswerIndex[1] + '-checked');
						}, 100);
				}
			};

			// ================
			// ---- Environment
			var environment = {questions: {}};

			if (gender) {
				environment['questions']['start-gender'] = {answers: [gender + '-opt']};
			}

			if (group) {
				environment['questions']['start-group'] = {answers: [group]};
			}

			// ================
			// ---- Translations
			var translations = {};

			// Localize standard templates
			translations['en'] = {
				'restart': 'Start Over',
				'view-recommendations': 'See Results',
				'choose details': 'Choose size and color',
				'Match': 'Match'
			};

			translations['fr'] = {
				'next': 'Suivant',
				'restart': 'Recommencer',
				'view-recommendations': 'Voir résultats',
				'choose details': 'Choisir la taille et la couleur',
				'Match': 'Correspond'
			};

			translations['de'] = {
				'next': 'Weiter',
				'restart': 'Nochmal anfangen',
				'view-recommendations': 'Ergebnisse anzeigen',
				'choose details': 'Wähle deine Größe und gewünschte Farbe aus',
				'Match': 'zutreffend'
			};


			// ================
			// ---- Helpers
			var helpers = {};

			helpers['getProductUrl'] = function () {
				return (Urls.getProductUrl);
			};


			// ================
			// ---- Templates
			var templates = {};

			templates['questiongroupbefore'] ='<div class="question-group-v-wrap">';
			templates['questiongroupafter'] ='</div>';
			// NOTE: These Product Related templates only effect the fallback results since the Product-HitTile fetch up above overwrites this
			templates['productlink'] = '{{getProductUrl}}/?pid={{product.externalId}}';
			templates['productaddtocart'] = '{{ref ""}}{{ref @root}}<a class="btn btn-secondary" href="{{> productlink this}}">{{text "choose details" @translations @languageCode}}</a></button>';
			templates['productmatch'] = '<div class="match"><span class="trigger" data-match="{{matchPercent}}">{{plan.matchPercent}}% {{text "Match" @translations @languageCode}}</span></div>';
			templates['productimage'] = '<div class="product-image"><img src="{{> productimagemedia product}}" title="{{attr name}}" /></div>';
			templates['productname'] = '{{ref ""}}{{ref @root}}<div class="name"><a href="{{> productlink this}}">{{product.name}}</a></div>';
			templates['product'] = '{{ref ""}}{{ref @root}}<div class="product swiper-slide {{#if segment.slug}}segment-{{segment.slug}}{{/if}}" data-product-id="{{attr product.externalId}}" {{#if product.parentProduct}}data-parent-product-id="{{attr product.parentProduct.externalId}}"{{/if}} {{#if segment.name}}data-segment="{{attr segment.name}}"{{/if}}>{{> productmatch this}}<div class="product-tile">{{> productimage this}}{{> productname this}}<div class="product-description">{{product.description}}</div>{{> productaddtocart this}}</div></div>';
			templates['recommendationsfooter'] = '{{ref ""}}{{ref @root}}<div class="swiper-controls"><div class="swiper-pagination"></div><div class="swiper-button-prev icon icon-icon-arrow-left"></div><div class="swiper-button-next icon icon-icon-arrow-right"></div></div>';


			// ================
			// ---- Initialize recommender
			var cartographerConfig = {
				// Project API key passed from the content asset
				project: apiKey,
				languageCode: language,
				container: $(container),
				recommendations: count
			};

			cartographerConfig['events'] = events;
			cartographerConfig['environment'] = environment;
			cartographerConfig['translations'] = translations;
			cartographerConfig['helpers'] = helpers;
			cartographerConfig['templates'] = templates;

			// Start recommender experience
			var recommender = new Drive.GuidedRecommender(cartographerConfig);
			return recommender.run();
		}));
	},

	// ========

	// ----
	// Sprinkle some background urls that were passed
	customBackgrounds: function(container, bg, bgSm) {

		// NOTE: Magic number width is equivalent to sass $small breakpoint size
		if (parseInt($(window).width()) < 768 && bgSm) {
			bgSm ? $(container + ' .question-groups').css('background-image', 'url(' + bgSm + ')') : null;
		} else {
			// NOTE: bg is required, so assumed to be fulfilled
			$(container + ' .question-groups').css('background-image', 'url(' + bg + ')');
		}

	},

	customBackgroundsGender: function(container, bgGenderSm, bgGenderMens, bgGenderWomens) {

		bgGenderSm ? $(container + ' .slug-start').css('background-image', 'url(' + bgGenderSm + ')') : null;
		bgGenderMens ? $(container + ' .slug-start .bg-left').css('background-image', 'url(' + bgGenderMens + ')') : null;
		bgGenderWomens ? $(container + ' .slug-start .bg-right').css('background-image', 'url(' + bgGenderWomens + ')') : null;

	},

	// ----
	// Slider
	buildSwiper: function(swiperSelector) {

		new Swiper(swiperSelector, {
			slideClass: 'swiper-slide',
			wrapperClass: 'swiper-wrapper',
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'fraction'
			},
			setWrapperSize: true,
			renderFraction: function (currentClassName, totalClassName) {
				return '<span class="' + currentClassName + '"></span> of <span class="' + totalClassName + '"></span> Results';
			},
			simulateTouch: false,
			spaceBetween: 0,
			speed: 400
		});

		$('.product').show();
		$('.swiper-controls').show();
	}
};

module.exports = cartographer;
