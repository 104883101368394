'use strict';

var refreshTooltips = {
	init: function () {

		// Custom skin to not interfere with "white"
		$.extend(Tipped.Skins, {
			'icebreaker-primary': {
				border: {size: 0},
				background: '#ffffff',
				radius: {size: 0},
				shadow: {
					blur: 4,
					color: '#000',
					offset: {x: 0, y: 1},
					opacity: 0.12
				},
				stem: {
					height: 8,
					width: 12,
					spacing: 0
				}
			}
		});

		// Change Country EU & UK only
		if ($(".change-country").length > 0) {
			Tipped.create(".change-country", "changeCountryTooltip", {
				inline: true,
				maxWidth: 200,
				skin: 'white',
				hook: 'topleft',
				hideOn: 'click-outside'
			});
		}

		// Sales Tax (US,CA only)
		Tipped.create('.fa-question-circle', 'salesTaxTooltip', {inline: true, skin: 'white', maxWidth: 200});

	}
};

module.exports = refreshTooltips;
